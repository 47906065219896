body {
  font-family: "Poppins", sans-serif;
  line-height: 16px;
}

.left-menu li a.active {
  background-color: #ffff;
  font-weight: 700;
}
.ant-pagination {
  li {
    margin: 0;
    &:hover {
      border-color: #212529;
    }
    button {
      &:hover {
        border-color: #212529;
      }
    }
  }
  .ant-pagination-item-active {
    background-color: #212529;
    border-color: #212529;
    color: #fff;
  }
}

.anticon {
  vertical-align: 2px;
}

.cursor-pointer {
  cursor: pointer;
}

.legend {
  display: flex;
  align-items: center;
  justify-content: center;
  hr {
    display: block;
    flex: 1;
    margin: 0px 30px;
    height: 1px;
    background: #ddd;
    &.left {
      margin-left: 0px;
    }

    &.right {
      margin-right: 0px;
    }
  }
}

.form-control-select {
  .ant-select-selector {
    min-height: 46px;
    align-items: center;
    border-radius: 5px;
    overflow: hidden;
  }
}

.left-menu {
  .accordion > .card {
    overflow: hidden;
    border-radius: 11px;
    padding: 0 !important;
    box-shadow: none;
    border: 0;
    width: 100%;
    margin-bottom: 0;
  }
}

.left-logo {
  text-align: center;
  svg {
    width: 115px;
    height: 60px;
    &:hover path {
      fill: #fff;
      transition: all 0.4s ease-in-out;
    }
  }
}

.ql-editor {
  min-height: 200px;
}

.row-dragging {
  background: #fafafa;
  border: 1px solid #ccc;
  display: flex;
  justify-content: space-between;
}
.row-dragging td {
  padding: 16px;
}

.row-dragging .drag-visible {
  visibility: visible;
}

.ant-tooltip-inner {
  background: rgba(0, 0, 0, 0.5);
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
  vertical-align: 2px;
}
.ant-card-head-title {
  font-weight: 600;
  font-size: 20px;
}

.custom-positioning-modal {
  top: 0;
  max-width: 100% !important;
  margin: 0;
  width: 100vw !important;
  height: 100vh;
  .ant-modal-content {
    min-height: 100vh;
  }
  .ant-modal-close {
    display: none;
    // top: 10px;
    // right: 10px;
    // color: #fff;
    // border: 2px solid #fff;
    // border-radius: 50%;
    // padding: 3px 5px 5px;
    // .ant-modal-close-x {
    //   line-height: unset;
    //   height: auto;
    //   width: auto;
    // }
  }
}
.empty-text{
  font-size: 15px;
  font-style: italic;
  color: cadetblue;
}
.disable-div {
  pointer-events: none;
  opacity: 0.4;
}
i {
  cursor: pointer;
}

.ant-btn-primary[disabled]{
  color: gray;
}
.ant-table-thead>tr>th {
  font-weight: 700;
}

