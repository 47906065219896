.gallery-img {
  display: inline-block;
  margin-right: 20px;
  width: 100px;
  height: 100px;
  .action-img {
    position: absolute;
    top: 0;
    width: 100px;
    background: #0000004a;
    height: 100px;
    text-align: center;
    display: none;
    .delete-icon {
      margin-top: 41px;
      color: #b80707;
      font-size: 19px;
    }
  }
  &:hover {
    .action-img {
      display: block;
    }
  }
}
.test {
  display: inline-block;
  margin-right: 20px;
  width: 100px;
  height: 100px;
}
.product-modal {
  .ant-modal-title {
    font-weight: 600;
  }
  .ant-modal-content {
    min-height: 100vh;
    border-radius: 0;
    position: relative;
    .ant-modal-footer {
      position: fixed;
      bottom: 0;
      width: 100%;
      background-color: #fff;
    }
  }
}

